:root {
  --primary: #0e59da;
  --secondary: #f65f1f;
  --primary-light: #e6eeff;
  --background: #ffffff;
  --background-primary: #ffffff;
  --text-primary: var(--primary);
  --background-primary-light: #e6eeff;
  --icon-hover-primary: #ffffff;
  --paragraph-text-primary: #171717;
}

[data-theme="dark"] {
  --primary: #141d24;
}
