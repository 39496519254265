* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
}

@font-face {
  font-family: "montserrat";
  src: url("/public/fonts/montserrat-variablefont_wght-webfont.woff2")
      format("woff2"),
    url("/public/fonts/montserrat-variablefont_wght-webfont.woff")
      format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "montserrat_italic";
  src: url("/public/fonts/montserrat-italic-variablefont_wght-webfont.woff2")
      format("woff2"),
    url("/public/fonts/montserrat-italic-variablefont_wght-webfont.woff")
      format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "montserratbold_italic";
  src: url("/public/fonts/montserrat-bolditalic-webfont.woff2") format("woff2"),
    url("/public/fonts/montserrat-bolditalic-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "montserrat_medium";
  src: url("/public/fonts/montserrat-medium-webfont.woff2") format("woff2"),
    url("/public/fonts/montserrat-medium-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'montserrat_regular';
  src: url('/public/fonts/montserrat-regular-webfont.woff2') format('woff2'),
       url('/public/fonts/montserrat-regular-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}

@font-face {
  font-family: 'montserrat_bold';
  src: url('/public/fonts/montserrat-bold-webfont.woff2') format('woff2'),
       url('/public/fonts/montserrat-bold-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

body {
  margin: 0;
  font-family: "montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
